import * as amplitude from '@amplitude/analytics-browser';
import axios from 'axios';
import moment from 'moment';
import { getResponsiveStyle } from 'muuri-react';
import constants from './en';
import { AppDefaults, DeviceTypeEnum } from './enums';
import roles from './roleEnums';
import { ducloConfig } from './configuration/ducloConfig';
import { megaTron } from './configuration/megaTron';
import timezones from '../data/support/timezone.json';
import { Utils } from '.';
import { MdPeopleOutline } from "react-icons/md";
import { FaRegFaceGrin } from "react-icons/fa6";
import { FaCarSide } from "react-icons/fa";
import { IoBicycle } from "react-icons/io5";
import { TbBus } from "react-icons/tb";
import { TbMotorbike } from "react-icons/tb";
import { HiOutlineTruck } from "react-icons/hi";
import { FaRegRectangleList } from "react-icons/fa6";


const objectURLMap = new Map();

/**
 *
 * @param {*} organizationsList
 * @returns user role
 */
const getLoggedInUserRole = (organizationsList) => {
  let currentUserRole = '';
  let loopStatus = '';
  const roleTypes = [
    roles.ROLE4VMS,
    roles.ROLE5VMS,
    roles.ROLE2VMS,
    roles.ROLE1VMS,
    roles.ROLE3VMS,
  ];

  roleTypes.forEach((role, i) => {
    if (loopStatus === 'stop') {
      return;
    }

    const userFound = organizationsList.filter(function (element) {
      return element.role === role;
    });

    if (userFound.length >= 1) {
      //=== If organization found with the role terminate the loop and return the role
      loopStatus = 'stop';
      currentUserRole = role;
    }
  });
  return currentUserRole;
};

export const areEqual = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;

  const sortedArr1 = arr1?.slice()?.sort();
  const sortedArr2 = arr2?.slice()?.sort();

  for (let i = 0; i < sortedArr1?.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }

  return true;
};

/**
 * Extracts the initials from a user's full name
 *
 * @param {String} fullName
 * @returns {String} first and last initials of the user
 */
const getInitialsFromFullName = (fullName) => {
  if (!fullName) return '';

  const names = fullName.split(' ');
  const firstNameInitial = names[0].charAt(0).toUpperCase();
  const lastNameInitial = names[1].charAt(0).toUpperCase();

  return firstNameInitial + lastNameInitial;
};

/**
 * Returns the account details of a specific account by organization ID and account ID
 *
 * @param {String} orgId - organization UD
 * @param {String} accountId - account ID
 * @returns
 */
const geAccountDetailsByAccountId = async (orgId, accountId) => {
  let responseObj;

  if (!orgId || !accountId) return;

  try {
    const res = await axios.get(`partner/orgs/${orgId}/accounts/${accountId}`, {
      ...requestHeader(),
      credentials: 'include',
      withCredentials: true,
    });

    const responseData = res?.data;

    if (responseData?.meta?.code === 200) {
      responseObj = responseData?.data;
    } else {
      if (res?.code) {
        Utils.vmsLogger().error(`${res.code}: ${res.message}`);
      } else if (responseData?.data) {
        Utils.vmsLogger().error(responseData?.data?.userMsg);
      }
    }
  } catch (error) {
    Utils.vmsLogger().error(error);
  } finally {
    return responseObj;
  }
};

/**
 * Retrieves the remote URL to the account's avatar image
 *
 * @param {String} orgId - Organization Identifier
 * @param {String} accountId - Account Identifier
 * @returns
 */
const getAccountAvatarURL = async (orgId, accountId) => {
  let responseObj = {
    status: 'ABORTED',
    avatarURL: '',
    errorMessage: '',
  };

  if (!orgId || !accountId) return;

  try {
    const res = await axios.get(`partner/orgs/${orgId}/accounts/${accountId}`, {
      ...requestHeader(),
      credentials: 'include',
      withCredentials: true,
    });

    const responseData = res?.data;

    if (responseData?.meta?.code === 200) {
      responseObj.status = 'SUCCESS';
      responseObj.avatarURL = responseData?.data?.image?.url;
    } else {
      responseObj.status = 'ERROR';
      responseObj.errorMessage = 'ERROR: Unable to retrieve Avatar Image URL';
    }
  } catch (error) {
    Utils.vmsLogger().error(error);
    responseObj.status = 'ERROR';
    responseObj.errorMessage = error;
  } finally {
    return responseObj;
  }
};

/**
 *
 * @param {*} devicesList
 * @returns Returns count of the offline cameras
 */
const getOfflineCameras = (devicesList) => {
  let offlineCamerasCount = 0;
  if (Array.isArray(devicesList) && devicesList?.length >= 1) {
    let deviceState, connectionState;
    devicesList.forEach((device, i) => {
      deviceState = device.deviceStatus;
      connectionState = device.connectionStatus;
      if (
        deviceState === constants.DEVICES_ENTERED_DEVICE_STATUS &&
        (!connectionState ||
          connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
          connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS)
      ) {
        offlineCamerasCount++;
      } else if (
        deviceState === constants.DEVICES_REGISTERED_DEVICE_STATUS &&
        connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS
      ) {
        offlineCamerasCount++;
      } else if (
        deviceState === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
        connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS
      ) {
        offlineCamerasCount++;
      } else if (
        deviceState === constants.DEVICES_DEACTIVATED_DEVICE_STATUS &&
        connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS
      ) {
        offlineCamerasCount++;
      }
    });
  }
  return offlineCamerasCount;
};

/**
 *
 * @param {*} devicesList
 * @returns Returns count of the offline cameras
 */
const getUnclaimedDevices = (devicesList) => {
  let unclaimedDevicesCount = 0;
  if (devicesList.length >= 1) {
    devicesList.forEach((device, i) => {
      if (
        device.status === constants.DEVICES_REGISTERED_DEVICE_STATUS &&
        device.connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        unclaimedDevicesCount++;
      }
    });
  }
  return unclaimedDevicesCount;
};

/**
 *
 * @param {*} licenseList
 * @returns Returns count of the liecenses expiring (Note: this is not done yet waiting for API to be update)
 */
const getLicensesExpiring = (licenseList) => {
  return 0;
};

/**
 * Checks if the license is already expired
 *
 * @param {Boject} license
 * @returns {Boolean}
 */
const isLicenseExpired = (license) => {
  if (!license) return true;

  const licenseDateTime = moment(license?.expiryDate);
  const currentDateTime = moment();

  return licenseDateTime.isBefore(currentDateTime);
};

/**
 *
 * @param {*} policiesList
 * @returns Returns the updated policies list based logged in user policies
 */
const mapUserPolicies = (allPoliciesList, userPoliciesList) => {
  let newPolicies = Object.assign({}, allPoliciesList);
  if (checkObjectEmpty(newPolicies) && userPoliciesList?.length >= 1) {
    userPoliciesList.forEach((policy, i) => {
      let policyName = policy.policyName;
      newPolicies[policyName] = true;
    });
    return newPolicies;
  }
  return newPolicies;
};

/**
 * Compares two arrays of objects for equality
 *
 * @param {Array} array1
 * @param {Array} array2
 * @returns {Boolean}
 */
const areArraysOfObjectsEqual = (array1 = [], array2 = []) => {
  // Check if the arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Compare each object in the arrays
  for (let i = 0; i < array1.length; i++) {
    // Check if the objects have the same number of properties
    if (Object.keys(array1[i]).length !== Object.keys(array2[i]).length) {
      return false;
    }

    // Compare the properties of each object
    for (let key in array1[i]) {
      if (array1[i][key] !== array2[i][key]) {
        return false;
      }
    }
  }

  return true;
};

/**
 *
 * @param {*} object variable
 * @returns Returns if passed value is object and not empty
 */
const checkObjectEmpty = (objVar) => {
  if (
    objVar && // 👈 null and undefined check
    Object.keys(objVar).length >= 1 &&
    Object.getPrototypeOf(objVar) === Object.prototype
  ) {
    return true;
  }
  return false;
};

/**
 * This function takes an array as the first input parameter
 * and an arguments list (array element values) as the
 * second parameter.  We use the classic function declaration
 * in order to have access to the native arguments parameter.
 *
 * @param {Array} arr
 * @returns new array
 */
function removeArrayElement(arr) {
  let args = arguments,
    argLength = args.length,
    elemToRemove,
    elemToRemoveIndex;

  while (argLength > 1 && arr.length) {
    elemToRemove = args[--argLength];

    while ((elemToRemoveIndex = arr.indexOf(elemToRemove)) !== -1) {
      arr.splice(elemToRemoveIndex, 1);
    }
  }

  return arr;
}

/**
 *
 * @returns Returns true/false if view is mobile view or not
 */
const checkIfMobileView = () => {
  const currentWidth = window.innerWidth;
  return currentWidth <= 768 ? true : false;
};

/**
 * Checks if the supplied url is valid/accessible
 *
 * @param {String} url
 * @returns {Boolean}
 */
const checkURLValidity = async (url) => {
  let retVal = false;

  if (!url) return retVal;

  try {
    const response = await fetch(url, {
      method: 'HEAD', // We only need the headers to check the status
    });
    // const response = await axios.head(url);
    retVal =
      response?.ok ||
      response?.code === 'ERR_NETWORK' ||
      response?.status === 200;
  } catch (error) {
    vmsLogger().error('Error validating URL:', error);
  } finally {
    return retVal;
  }
};

/**
 * Initializes the browser cache with given cache name
 *
 * @param {string} cacheName
 */
const initCache = async (cacheName) => {
  try {
    if (!cacheName) throw new Error('ERROR: Invalid cache name');

    if ('caches' in window) {
      await caches.open(cacheName);
    } else {
      console.warn('Cache API not supported in this browser.');
    }
  } catch (err) {
    Utils.vmsLogger().error(err);
  }
};

/**
 * Filename generator, used for caching files
 *
 * @param {string} imageID - ID used to identify the image
 * @param {string} prefix - prefix prepended to filename
 * @param {string} extension - file extension
 * @returns
 */
const generateCacheKey = (
  imageID,
  prefix = 'snapshot-image',
  extension = 'jpg'
) => {
  return `${prefix}-${imageID}.${extension}`;
};

/**
 * Fetches JPEG files and converts them into Response objects.
 *
 * @param {string[]} jpegSources - Array of JPEG URLs.
 * @returns {Promise<Array<{ request: Request, response: Response }>>}
 */
const fetchJpegResponses = async (jpegSources, jpegSourceId, jpegSourceUrl) => {
  if (!Array.isArray(jpegSources) || !jpegSourceId || !jpegSourceUrl) {
    Utils.vmsLogger().error('Invalid arguments provided');
    return;
  }

  const fetchPromises = jpegSources.map(async (jpegSource) => {
    const id = jpegSource[jpegSourceId];
    const url = jpegSource[jpegSourceUrl]?.url;

    // Fetch the JPEG file and convert it into a Response object.
    // Note: This is a simplistic example and doesn't handle error cases.
    // In a real-world scenario, you would want to handle errors more gracefully.
    try {
      const response = await fetch(url, {
        cache: 'no-store',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
      }

      const responseClone = response.clone();

      return { key: generateCacheKey(id), response: responseClone };
    } catch (error) {
      Utils.vmsLogger().error(`Error fetching ${url}: `, error);
      return null;
    }
  });

  const results = await Promise.all(fetchPromises);

  // Filter out any failed fetches
  return results.filter((item) => item !== null);
};

/**
 * Stores JPEG Response objects in Cache Storage.
 *
 * @param {Array<{ request: Request, response: Response }>} entries
 * @param {string} cacheName
 * @returns
 */
const storeJpegsInCache = async (entries, cacheName) => {
  if (!('caches' in window)) {
    Utils.vmsLogger().error('Cache API is not supported in this browser.');
    return;
  }

  try {
    if (!cacheName) {
      throw new Error('Cache name must be provided');
    }

    const cache = await caches.open(cacheName);
    for (const entry of entries) {
      // Store in Cache storage only if entry is a valid
      // object that has 'key' and 'response' properties
      if (entry?.key && entry?.response) {
        await cache.put(entry.key, entry.response);
        Utils.vmsLogger().log(`Cached: ${entry.key}`);
      } else {
        throw new Error('Entry is not a valid image');
      }
    }
    Utils.vmsLogger().log('All JPEG images have been cached successfully.');
  } catch (error) {
    Utils.vmsLogger().error('Failed to cache JPEG images: ', error);
  }
};

/**
 * Retrieves a cached JPEG.
 *
 * @param {string} key - primary key of item in cache
 * @param {string} sourceURL - presigned URL of resource
 * @param {string} cacheName - name of cache
 * @param {string} id - alternate ID for item in cache
 * @returns
 */
const getJpegFromCache = async (key, sourceURL, cacheName, id) => {
  if (!sourceURL || !cacheName) {
    return;
  }

  let itemKey = key === 'fetch' ? generateCacheKey(id) : key;

  if (!('caches' in window)) {
    Utils.vmsLogger().error('Cache API is not supported in this browser.');
    return;
  }

  try {
    const cache = await caches.open(cacheName);
    const cachedResponse = await cache.match(itemKey);

    if (cachedResponse && cachedResponse.blob) {
      const blob = await cachedResponse.blob();

      return URL.createObjectURL(blob);
    } else {
      Utils.vmsLogger().warn('Image not found in cache: ', itemKey);
      // Fetch URL
      const networkResponse = await fetch(sourceURL, {
        cache: 'no-store',
      });

      if (networkResponse.ok) {
        const blob = await networkResponse.blob();
        const imgUrl = URL.createObjectURL(blob);

        // Optionally cache the fetched image
        if (itemKey && networkResponse.clone) {
          await cache.put(itemKey, networkResponse.clone());
        }
        Utils.vmsLogger().log(`Fetched and cached image: ${itemKey}`);
        return imgUrl;
      } else {
        throw new Error(
          `Network response was not ok for ${itemKey} at ${sourceURL}`
        );
      }
    }
  } catch (error) {
    Utils.vmsLogger().error('Failed to get JPEG from cache: ', error);
  }
};

/**
 * Revokes all object URLs. Call this when the component unmounts or as needed.
 */
const revokeAllObjectURLs = () => {
  objectURLMap.forEach((url) => {
    URL.revokeObjectURL(url);
  });
  objectURLMap.clear();
};

/**
 *
 * @param {String} price - display price
 * @returns {JSX} - JSX element to render
 */
const formatCurrencyWithSmallDecimals = (price) => {
  let separator = price.indexOf('.') !== -1 ? '.' : ',';
  let parts = price.split(separator);

  if (!Array.isArray(parts) || parts.length < 2) {
    parts = ['$0', `${separator}00`];
  }

  return (
    <>
      <span>{parts[0]}</span>
      <span className="small-decimals">.{parts[1]}</span>
    </>
  );
};

/**
 *
 * @param {string} featureType - feature type
 * @param {Object} product - product object
 * @returns {*} - value of specified feature
 */
const getFeatureValue = (featureType, product) => {
  if (
    !product ||
    !Array.isArray(product.featureTypes) ||
    !Array.isArray(product.features)
  ) {
    return 0;
  }

  const featureTypeIndex = product.featureTypes.findIndex((ft) => {
    return ft.featureType === featureType;
  });

  if (featureTypeIndex !== -1) {
    const featureTypeId = product.featureTypes[featureTypeIndex].featureTypeId;

    const featureIndex = product.features.findIndex((feature) => {
      return feature.featureTypeId === featureTypeId;
    });

    if (featureIndex !== -1) {
      // Add specific feature type to query in this switch statement
      switch (featureType) {
        case 'SERVICE_DURATION':
          return product.features[featureIndex].durationInDays;

        default:
          return product.features[featureIndex].featureName;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

/**
 * Determines the greatest common divisor between two numbers
 *
 * @param {Number} num1
 * @param {Number} num2
 * @returns
 */
const getGreatestCommonDivisor = (num1 = 0, num2 = 0) => {
  return num2 === 0 ? num1 : getGreatestCommonDivisor(num2, num1 % num2);
};

/**
 * Converts the epoch of date and time with different time zone to
 * epoch of same date and time of local
 *
 * @param {*} dateTimeEpoch
 * @param {*} timezone
 * @returns Returns local date and time epoch value
 */
const convertTimezoneDateToLocalDate = (dateTimeEpoch, timezone) => {
  var localDateTime = new Date(
    moment.tz(dateTimeEpoch, timezone).format('lll')
  );

  return localDateTime.getTime();
};

/**
 *
 * @param {*} Date
 * @returns Returns unix date & time
 */
const getUnixDate = (date) => {
  return moment(new Date(date)).unix();
};

/**
 *
 * @param {*} Date
 * @returns Returns unix date
 */
const fetchDateInUnix = (date) => {
  const input = new Date(date * 1000); // Convert UNIX timestamp to milliseconds
  input.setUTCHours(0, 0, 0, 0); // Set the time to midnight
  const output = Math.floor(input.getTime() / 1000); // Convert milliseconds back to UNIX timestamp
  return output;
};

/**
 *
 * @param {*} UnixTimestamp
 * @returns Returns original date
 */
const getDate = (unixDate) => {
  return moment.unix(unixDate).toDate();
};

/**
 *
 * @param {Number} startUnixTime
 * @param {Number} endUnixTime
 * @returns Returns the difference between the two unix times in number of days
 */
const getDateDifferenceInDays = (startUnixTime, endUnixTime) => {
  if (isNaN(startUnixTime) || isNaN(endUnixTime)) return 0;

  const startTime = moment(startUnixTime);
  const endTime = moment(endUnixTime);

  if (!startTime || !endTime) return 0;

  return Math.ceil(moment.duration(startTime?.diff(endTime)).asDays());
};

/**
 * Retrieves the date format string based on supplied locale
 * @param {String} locale - locale to use for date format lookup
 * @returns {String} date format string
 */
const getDateFormatStringByLocale = (locale) => {
  const dateFormat = '../data/support/dateformat.json';
  let givenLocale;

  if (!locale) return 'MM/DD/YYYY';

  // Ensure the locale is in all lowercase
  givenLocale = locale.toLowerCase();

  return dateFormat[givenLocale] || 'MM/DD/YYYY';
};

/**
 * Returns the difference between two unix times
 *
 * @param {Number} pastTime - time in the past in milliseconds
 * @param {Number} currentTime - current time in milliseconds
 * @returns {String} the difference in appropriate unit of time.
 */
const getElapsedTime = (pastTime, currentTime) => {
  if (isNaN(pastTime) || isNaN(currentTime)) return 0;

  // Calculate the difference using moment.js
  const momentDifference = moment?.duration(currentTime - pastTime);

  // Get the years, months, weeks, days, hours, minutes, and seconds
  const years = Math.floor(moment?.duration(momentDifference)?.asYears());
  const months = Math.floor(moment?.duration(momentDifference)?.asMonths());
  const weeks = Math.floor(moment?.duration(momentDifference)?.asWeeks());
  const days = Math.floor(moment?.duration(momentDifference)?.asDays());
  const hours = Math.floor(moment?.duration(momentDifference)?.asHours());
  const minutes = Math.floor(moment?.duration(momentDifference)?.asMinutes());
  const seconds = Math.floor(moment?.duration(momentDifference)?.asSeconds());

  // Select the appropriate string based on the values
  if (momentDifference === 0) {
    return 'Just now';
  } else if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    if (seconds < 1) {
      return 'Just now';
    } else {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    }
  }
};

/**
 * Retrieves the starting and ending offsets in pixels relative
 * to a midpoint location on the x-axis.
 *
 * @param {Number} absoluteMidPointLocation - midpoint location along the
 * video player timeline
 * @param {Number} beforeLocation - starting location of clipper on x-axis
 * @param {Number} clipperWidth - length of clipper in pixels
 *
 * @returns {Object|Null} before and after offsets in pixels
 */
const calculateBeforeAndAfterOffsets = (
  absoluteMidPointLocation,
  beforeLocation,
  clipperWidth
) => {
  const midPoint = absoluteMidPointLocation;
  let startOffset,
    shouldAddStartOffset,
    endOffset,
    shouldAddEndOffset,
    endLocation,
    beforeAndAfterOffsets;

  try {
    if (isNaN(beforeLocation) || isNaN(midPoint) || isNaN(clipperWidth)) {
      throw new Error(
        'ERROR: missing required parameters for calculateBeforeAndAfterOffsets()'
      );
    }

    // Calculate the end point's location on timeline
    endLocation = parseInt(beforeLocation) + parseInt(clipperWidth);

    // Calculate Start Offset
    if (midPoint > beforeLocation) {
      startOffset =
        midPoint -
        (beforeLocation + AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH);
      shouldAddStartOffset = false;
    } else {
      startOffset =
        beforeLocation -
        (midPoint + AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH);
      shouldAddStartOffset = true;
    }

    // Calculate End Offset
    if (midPoint > endLocation) {
      endOffset =
        midPoint -
        (endLocation - AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH);
      shouldAddEndOffset = false;
    } else {
      endOffset =
        endLocation -
        (midPoint + AppDefaults.INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH);
      shouldAddEndOffset = true;
    }

    beforeAndAfterOffsets = {
      startOffset,
      shouldAddStartOffset,
      endOffset,
      shouldAddEndOffset,
    };
  } catch (error) {
    beforeAndAfterOffsets = null;
    Utils.vmsLogger().error(error);
  } finally {
    return beforeAndAfterOffsets;
  }
};

/**
 * Calculates the before and after times relative to a reference time
 *
 * @param {Number} referenceUnixTime - the reference point in time
 * @param {Number} beforeOffset - number of seconds before the reference time
 * @param {Boolean} addBeforeOffset - should the before offset be added to the
 * reference unix time
 * @param {Number} afterOffset - the number of seconds after the reference time
 * @param {Boolean} addAfterOffset - should the after offset be added to the
 * reference unix time
 * @returns {Object} beforeAndAfterUnixTimes - before and after time values
 * in unix time format
 */
const getBeforeAndAfterUnixTimes = (
  referenceUnixTime,
  beforeOffset,
  addBeforeOffset,
  afterOffset,
  addAfterOffset
) => {
  let beforeAndAfterUnixTimes = null;
  let timeBefore, timeAfter;

  try {
    if (isNaN(referenceUnixTime) || isNaN(beforeOffset) || isNaN(afterOffset)) {
      throw new Error(
        'ERROR: missing required parameters for getBeforeAndAfterUnixTimes()'
      );
    }

    // Convert UNIX time to moment object
    const currentTime = moment.unix(referenceUnixTime);

    // Calculate time beforeOffset seconds before the reference time
    if (addBeforeOffset) {
      timeBefore = currentTime.clone().add(beforeOffset, 'seconds');
    } else {
      timeBefore = currentTime.clone().subtract(beforeOffset, 'seconds');
    }
    const timeBeforeUNIX = timeBefore.valueOf();

    // Calculate time afterOffset seconds after the reference time
    if (addAfterOffset) {
      timeAfter = currentTime.clone().add(afterOffset, 'seconds');
    } else {
      timeAfter = currentTime.clone().subtract(afterOffset, 'seconds');
    }
    const timeAfterUNIX = timeAfter.valueOf();

    beforeAndAfterUnixTimes = {
      beforeTime: timeBefore,
      beforeUnixTime: timeBeforeUNIX,
      afterTime: timeAfter,
      afterUnixTime: timeAfterUNIX,
    };
  } catch (error) {
    Utils.vmsLogger().error(error);
  } finally {
    return beforeAndAfterUnixTimes;
  }
};

/**
 *
 * @param {*} device status and connection status
 * @returns Returns UX status
 */
const getDeviceStatus = (deviceStatus, connectionStatus) => {
  if (
    deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_OFFLINE_STATUS;
  } else if (deviceStatus === constants.DEVICES_DEACTIVATED_DEVICE_STATUS) {
    return constants.DEVICES_RETURN_DEACTIVATED_STATUS;
  } else if (
    (deviceStatus === constants.DEVICES_ENTERED_DEVICE_STATUS ||
      deviceStatus === constants.DEVICES_REGISTERED_DEVICE_STATUS) &&
    (connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
      connectionStatus === undefined ||
      connectionStatus === null)
  ) {
    return constants.DEVICES_RETURN_ENTERED_STATUS;
  } else if (
    deviceStatus === constants.DEVICES_REGISTERED_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_READY_TO_CLAIM_STATUS;
  } else if (
    deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_CLAIMING_STATUS;
  } else if (
    deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_CLAIMING_STATUS;
  } else if (
    deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
    connectionStatus === constants.DEVICES_ONLINE_CONNECTION_STATUS
  ) {
    return constants.DEVICES_RETURN_ONLINE_STATUS;
  } else if (
    !connectionStatus &&
    deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS
  ) {
    return constants.DEVICES_RETURN_CLAIMING_STATUS;
  } else {
    return constants.DEVICES_RETURN_OFFLINE_STATUS;
  }
};

/**
 *
 * @param {*} string, string replace key, string replace value
 * @returns Returns string
 */
const replaceStringValues = (str, key, value) => {
  return str?.replace(key, value);
};

/**
 * Replaces string tokens within a string with supplied values
 *
 * @param {String} sourceString - the source string
 * @param {Array} keyValueArray - array of key-value pairs
 * @returns {String} - new string with correct values
 */
const replaceStringWithKeyValueArray = (sourceString, keyValueArray) => {
  let newString;

  if (
    !sourceString ||
    !Array.isArray(keyValueArray) ||
    keyValueArray.length < 1
  )
    return;

  newString = sourceString;

  keyValueArray.forEach((keyValue) => {
    newString = newString.replace(keyValue.key, keyValue.value);
  });

  return newString;
};

const encodeImageFileToBase64String = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const decodeBase64StringToImageFile = (string64, fileName) => {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);
  const type = 'image/jpeg';
  const blob = new Blob([buffer], { type });

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type,
  });
};

//this will return datevalue in millisecond
const daysToMilliseconds = (dateValue) => {
  return (dateValue + 1) * 24 * 60 * 60 * 1000;
};

//Format Mac Address with colon
const formatMACAddress = (e) => {
  const r = /([a-f0-9]{2})([a-f0-9]{2})/i;
  let str = e.replace(/[^a-f0-9]/gi, '');

  while (r.test(str)) {
    str = str.replace(r, '$1' + ':' + '$2');
  }

  return (e = str.slice(0, 17));
};

const getCurrentTabProperty = (data, tabresourcse) => {
  const deviceProperty = data?.find(
    (device) => device.resource === tabresourcse
  );

  return deviceProperty?.properties;
};

const getTagsCategoryAvilable = () => {
  const tags = ['person', 'face', 'license plate', ''];
};

const getPropertyShowStatus = (
  deviceStatus,
  propertyName,
  capabilityProperty
) => {
  if (deviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS) {
    return (
      capabilityProperty && capabilityProperty.hasOwnProperty(propertyName)
    );
  } else {
    return true;
  }
};

const getPTZShowStatus = (deviceStatus, propertyName, capabilityProperty) => {
  if (
    deviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS &&
    capabilityProperty &&
    capabilityProperty.hasOwnProperty(propertyName)
  ) {
    return capabilityProperty[propertyName]?.[0] === 'false' ? false : true;
  } else {
    return false;
  }
};

const getDeviceSettingResource = (tabIndex) => {
  const deviceSettingResources = {
    0: ['camera/system/device-info', 'camera/system/date'],
    1: [
      'camera/image/rotate',
      'camera/image/wdr',
      'camera/media/video-profile',
      'camera/media/wisestream',
      'camera/image/ir-mode',
      'camera/image/image-enhancement',
    ],
    2: ['camera/image/focus'],
    3: ['camera/media/audio-input', 'camera/media/audio-output'],
    5: ['camera/network/ip-support'],
    6: [
      'camera/diag/restart',
      'camera/diag/full-reset',
      'camera/diag/duclo-fw-update',
      'camera/diag/device-fw-update',
      'diag/uploadLogs',
      'backup/local-storage',
      'backup/local-storage/config',
      'backup/local-storage/format',
      'backup/local-storage/status',
    ],
    7: [
      'camera/event/object-detection',
      'camera/event/motion-detection',
      'camera/event/shock-detection',
      'camera/event/tamper-detection',
      'camera/event/defocus-detection',
      'camera/event/audio-detection',
      'camera/event/virtual-line',
      'camera/event/include-area',
      'camera/event/queue-mgt',
      'camera/event/exclude-area',
      'camera/event/sound-class',
      'camera/event/fog-detection',
      'camera/event/obj-detection',
      'camera/event/virtual-area',
      'camera/event/iva-enable'
    ],
    8: ['camera/settings/cvr'],
    9: ['camera/settings/max-br'],
    10: ['camera/system/ptz', 'camera/system/fisheye'],
    11: ['hub/channels', 'hub/channels/auth-creds'],
  };
  if (isNaN(tabIndex) || tabIndex < 0 || tabIndex > 11) {
    return;
  } else {
    return deviceSettingResources[tabIndex];
  }
};

const getDropDownPair = (dataList) => {
  let dropDownList = [];
  if (dataList) {
    for (var i of dataList) {
      dropDownList.push({ label: i, value: i });
    }
  }
  return dropDownList;
};

const getDropDownPairRes = (originalArray, maxValue) => {
  const newArray = originalArray?.filter((resolution) => {
    const [width, height] = resolution?.split('x')?.map(Number);
    return width * height <= maxValue;
  });

  let dropDownList = [];
  if (newArray) {
    for (var i of newArray) {
      dropDownList.push({ label: i, value: i });
    }
  }
  return dropDownList;
};

const getPropertyValueIfExist = (value, isMax) => {
  if (isNaN(value)) {
    return 1;
  } else if (
    value && value?.toLowerCase() === constants.MEDIUM_VALUE_LABEL.toLowerCase()
  ) {
    return 2;
  } else if (value && value?.toLowerCase() === constants.MAX_VALUE_LABEL.toLowerCase()) {
    return 3;
  } else if (value && value?.toLowerCase() === constants.MIN_VALUE_LABEL.toLowerCase()) {
    return 1;
  } else if ((isMax || !isMax) && value) {
    return parseInt(value);
  } else if (isMax) {
    return 100;
  } else {
    return 1;
  }
};

const getCodecAvailability = (valueCodec, valueData) => {
  const codecStatus = valueData?.find(
    (codecData) => codecData.toUpperCase() === valueCodec.toUpperCase()
  );
  return codecStatus;
};

const getDetectionValue = (modalIndex, properties, modalData) => {
  if (isNaN(modalIndex) || modalIndex < 0 || modalIndex > 6) {
    return;
  }
  let detectionValue;
  switch (modalIndex) {
    case 1:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.['md-include-area-level'],
        valueSensitivity: properties?.['md-include-area-sensitivity'],
        valueDuration: properties?.['md-include-area-min-dur'],
      };
      break;
    case 2:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.['skd-level'],
        valueSensitivity: properties?.['skd-sensitivity'],
        valueDuration: properties?.['skd-min-dur'],
      };
      break;
    case 3:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.['td-level'],
        valueSensitivity: properties?.['td-sensitivity'],
      };
      break;
    case 4:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.['dfd-level'],
        valueSensitivity: properties?.['dfd-sensitivity'],
        valueDuration: properties?.['dfd-min-dur'],
      };
      break;
    case 5:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.['audio-level'],
      };
      break;
    case 6:
      detectionValue = {
        ...modalData,
        valueLevelDetection: properties?.['fd-level'],
        valueSensitivity: properties?.['fd-sensitivity'],
        valueDuration: properties?.['fd-min-dur'],
      };
      break;
    default:
  }
  return detectionValue;
};

const getModalData = (modalIndex, properties, modalData, resource) => {
  if (isNaN(modalIndex) || modalIndex < 0 || modalIndex > 11) {
    return;
  }
  let getModalValues;
  switch (modalIndex) {
    case 1:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.['md-include-area-level']?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.['md-include-area-level']?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.['md-include-area-level'],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.['md-include-area-sensitivity']?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.['md-include-area-sensitivity']?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.['md-include-area-sensitivity'],
          false
        ),
        maxDuration: getPropertyValueIfExist(
          modalData?.['md-include-area-min-dur']?.max,
          true
        ),
        minDuration: getPropertyValueIfExist(
          modalData?.['md-include-area-min-dur']?.min,
          false
        ),
        valueDuration: getPropertyValueIfExist(
          properties?.['md-include-area-min-dur'],
          false
        ),
        levelKey: 'md-include-area-level',
        sensitivityKey: 'md-include-area-sensitivity',
        mindurationKey: 'md-include-area-min-dur',
        resource: resource,
      };
      break;
    case 2:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.['skd-level']?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.['skd-level']?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.['skd-level'],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.['skd-sensitivity']?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.['skd-sensitivity']?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.['skd-sensitivity'],
          false
        ),
        levelKey: 'skd-level',
        sensitivityKey: 'skd-sensitivity',
        resource: resource,
      };
      break;
    case 3:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.['td-level']?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.['td-level']?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.['td-level'],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.['td-sensitivity']?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.['td-sensitivity']?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.['td-sensitivity'],
          false
        ),
        maxDuration: getPropertyValueIfExist(
          modalData?.['td-min-dur']?.max,
          true
        ),
        minDuration: getPropertyValueIfExist(
          modalData?.['td-min-dur']?.min,
          false
        ),
        valueDuration: getPropertyValueIfExist(
          properties?.['td-min-dur'],
          false
        ),
        levelKey: 'td-level',
        sensitivityKey: 'td-sensitivity',
        mindurationKey: 'td-min-dur',
        resource: resource,
      };
      break;
    case 4:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.['dfd-level']?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.['dfd-level']?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.['dfd-level'],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.['dfd-sensitivity']?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.['dfd-sensitivity']?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.['dfd-sensitivity'],
          false
        ),
        maxDuration: getPropertyValueIfExist(
          modalData?.['dfd-min-dur']?.max,
          true
        ),
        minDuration: getPropertyValueIfExist(
          modalData?.['dfd-min-dur']?.min,
          false
        ),
        valueDuration: getPropertyValueIfExist(
          properties?.['dfd-min-dur'],
          false
        ),
        levelKey: 'dfd-level',
        sensitivityKey: 'dfd-sensitivity',
        mindurationKey: 'dfd-min-dur',
        resource: resource,
      };
      break;
    case 5:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.['audio-level']?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.['audio-level']?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.['audio-level'],
          false
        ),
        levelKey: 'audio-level',
        resource: resource,
      };
      break;
    case 6:
      getModalValues = {
        maxLevel: getPropertyValueIfExist(modalData?.['sc-level']?.max, true),
        minLevel: getPropertyValueIfExist(modalData?.['sc-level']?.min, false),
        scLevelValue: getPropertyValueIfExist(properties?.['sc-level'], false),
        levelKey: 'sc-level',
        resource: resource,
      };
      break;
    case 7:
      getModalValues = {
        maxLevelDetection: getPropertyValueIfExist(
          modalData?.['fd-level']?.max,
          true
        ),
        minLevelDetection: getPropertyValueIfExist(
          modalData?.['fd-level']?.min,
          false
        ),
        valueLevelDetection: getPropertyValueIfExist(
          properties?.['fd-level'],
          false
        ),
        maxSensitivity: getPropertyValueIfExist(
          modalData?.['fd-sensitivity']?.max,
          true
        ),
        minSensitivity: getPropertyValueIfExist(
          modalData?.['fd-sensitivity']?.min,
          false
        ),
        valueSensitivity: getPropertyValueIfExist(
          properties?.['fd-sensitivity'],
          false
        ),
        maxDuration: getPropertyValueIfExist(
          modalData?.['fd-min-dur']?.max,
          true
        ),
        minDuration: getPropertyValueIfExist(
          modalData?.['fd-min-dur']?.min,
          false
        ),
        valueDuration: getPropertyValueIfExist(
          properties?.['fd-min-dur'],
          false
        ),
        levelKey: 'fd-level',
        sensitivityKey: 'fd-sensitivity',
        mindurationKey: 'fd-min-dur',
        resource: resource,
      };
      break;
    case 8:
        getModalValues = {
          maxDuration: getPropertyValueIfExist(
            modalData?.['obj-min-duration']?.max,
            true
          ),
          minDuration: getPropertyValueIfExist(
            modalData?.['obj-min-duration']?.min,
            false
          ),
          valueDuration: getPropertyValueIfExist(
            properties?.['obj-min-duration'],
            false
          ),
          mindurationKey: 'obj-min-duration',
          resource: resource,
        };
      break;
      case 9:
        getModalValues = {
          maxDuration: getPropertyValueIfExist(
            modalData?.['virtual-area-intrusion-min-dur']?.max,
            true
          ),
          minDuration: getPropertyValueIfExist(
            modalData?.['virtual-area-intrusion-min-dur']?.min,
            false
          ),
          valueDuration: getPropertyValueIfExist(
            properties?.['virtual-area-intrusion-min-dur'],
            false
          ),
          mindurationKey: 'virtual-area-intrusion-min-dur',
          resource: resource,
        };
      break;
      case 10:
        getModalValues = {
          maxDuration: getPropertyValueIfExist(
            modalData?.['virtual-area-appear-disappear-min-dur']?.max,
            true
          ),
          minDuration: getPropertyValueIfExist(
            modalData?.['virtual-area-appear-disappear-min-dur']?.min,
            false
          ),
          valueDuration: getPropertyValueIfExist(
            properties?.['virtual-area-appear-disappear-min-dur'],
            false
          ),
          mindurationKey: 'virtual-area-appear-disappear-min-dur',
          resource: resource,
        };
      break;
      case 11:
        getModalValues = {
          maxDuration: getPropertyValueIfExist(
            modalData?.['virtual-area-loitering-min-dur']?.max,
            true
          ),
          minDuration: getPropertyValueIfExist(
            modalData?.['virtual-area-loitering-min-dur']?.min,
            false
          ),
          valueDuration: getPropertyValueIfExist(
            properties?.['virtual-area-loitering-min-dur'],
            false
          ),
          mindurationKey: 'virtual-area-loitering-min-dur',
          resource: resource,
        };
      break;
    default:
  }
  return getModalValues;
};

const getWeekDays = (currentDay) => {
  if (!Array.isArray(currentDay) || currentDay?.length === 0) {
    return;
  }
  return [
    {
      dayPrefix: 'S',
      id: 1,
      day: 'Sunday',
      isSelected: currentDay?.find((day) => day === '1' || day === 1)
        ? true
        : false,
      shortName: 'Sun',
    },
    {
      dayPrefix: 'M',
      id: 2,
      day: 'Monday',
      isSelected: currentDay?.find((day) => day === '2' || day === 2)
        ? true
        : false,
      shortName: 'Mon',
    },
    {
      dayPrefix: 'T',
      id: 3,
      day: 'Tuesday',
      isSelected: currentDay?.find((day) => day === '3' || day === 3)
        ? true
        : false,
      shortName: 'Tue',
    },
    {
      dayPrefix: 'W',
      id: 4,
      day: 'Wednesday',
      isSelected: currentDay?.find((day) => day === '4' || day === 4)
        ? true
        : false,
      shortName: 'Wed',
    },
    {
      dayPrefix: 'T',
      id: 5,
      day: 'Thusday',
      isSelected: currentDay?.find((day) => day === '5' || day === 5)
        ? true
        : false,
      shortName: 'Thu',
    },
    {
      dayPrefix: 'F',
      id: 6,
      day: 'Friday',
      isSelected: currentDay?.find((day) => day === '6' || day === 6)
        ? true
        : false,
      shortName: 'Fri',
    },
    {
      dayPrefix: 'S',
      id: 7,
      day: 'Saturday',
      isSelected: currentDay?.find((day) => day === '7' || day === 7)
        ? true
        : false,
      shortName: 'Sat',
    },
  ];
};

const getStartEndMin = (cDate, isStartTime) => {
  let hour = Math.floor(new Date(cDate).getHours());
  let min = Math.floor(new Date(cDate).getMinutes());
  let startTime = hour * 60 + min;
  if (isStartTime) {
    return startTime;
  } else {
    return startTime > 1440 ? startTime - 1440 : startTime;
  }
};

const getHoursFromValue = (time) => {
  if (isNaN(time)) {
    return;
  }

  let h = Math.floor(time / 60);

  let m = time % 60;

  h = h < 10 ? '0' + h : h;

  m = m < 10 ? '0' + m : m;

  return h + ':' + m;
};

const getTimeStampFromDate = (timeHrSec) => {
  const time = getUnixDate(
    new Date(moment(new Date())).toDateString(`yyyy-MM-dd${timeHrSec}`)
  );
  return time * 1000;
};

const getPastDaysTime = (timeHrSec, days) => {
  const time = new Date(
    moment
      .tz(moment().subtract(days, 'days'), moment.tz.guess())
      .format('MMM DD, YYYY') +
      ' ' +
      timeHrSec
  ).getTime();
  return time;
};

const getHourPast = (days) => {
  const time = new Date(
    moment
      .tz(moment().subtract(days, 'days'), moment.tz.guess())
      .format('MMM DD, YYYY hh:mm:ss a')
  ).getTime();
  return time;
};

const getPastWeekDayName = (days) => {
  const weekDayName = moment(
    new Date(moment.tz(moment().subtract(days, 'days'), moment.tz.guess()))
  ).format('dddd');
  return weekDayName;
};

const getHourPastByValue = (hoursValue) => {
  const time = new Date(
    moment
      .tz(moment().subtract({ hours: hoursValue }), moment.tz.guess())
      .format('MMM DD, YYYY hh:mm:ss a')
  ).getTime();
  return time;
};

const getTimeFilters = () => {
  const time = [
    { id: 0, value: 'All time' },
    { id: 2, value: 'Past Hour' },
    { id: 1, value: 'Past 24 Hour' },
    { id: 7, value: 'Last 7 days' },
    { id: 15, value: 'Last 15 days' },
    { id: 30, value: 'Last 30 days' },
    { id: 8, value: 'Custom Range' },
  ];
  return time;
};

const getHourFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment(milliseconds).format('hh:mm');
};

/**
 * Converts a time in milliseconds to Date string
 *
 * @param {Number} milliseconds
 * @returns Date string in MM/DD format
 */
const getDateFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment.utc(milliseconds).startOf('day').format('MM/DD');
};

const getWeekFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment.utc(milliseconds).startOf('day').format('MM/DD');
};

const getMonthFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  const date = moment.utc(milliseconds);
  const month = date.format('MMM');
  const year = date.format('YY');
  return `${month}-${year}`;
};

const getYearFromEpoch = (milliseconds) => {
  if (isNaN(milliseconds)) {
    return;
  }

  return moment.utc(milliseconds).startOf('day').format('YYYY');
};

/**
 *
 * @param {Number} dayRange - target number of days
 * @param {*} endDateMilliseconds - end date in milliseconds (Epoch)
 * @returns Array of past number of days in milliseconds
 */
const getPastDaysInMilliseconds = (dayRange, endDateMilliseconds) => {
  const pastDays = [];

  if (isNaN(dayRange) || isNaN(endDateMilliseconds)) {
    return;
  }

  for (let i = 0; i < dayRange; i++) {
    // loop to subtract 1 day in each iteration
    const pastDate = moment(endDateMilliseconds).subtract(i, 'days').valueOf();
    pastDays.push(pastDate);
  }

  return pastDays;
};

/**
 * Retrieves the time zone value for a specific format (POSIX or Olson)
 *
 * @param {String} timezoneValue - input time zone value
 * @param {String} format - desired format for output (defaults to timezone object)
 *
 * @returns {String|Object}
 */
const getTimezone = (timezoneValue, format) => {
  let returnValue = null,
    timezoneObject;
  // Regex for location value (i.e. America/New_York)
  const locationRegex = /^.*\/.*$/;

  try {
    if (!timezoneValue || typeof timezoneValue !== 'string') {
      throw new Error('ERROR: must provide timezone value');
    }

    if (
      !timezones ||
      typeof timezones !== 'object' ||
      !Array.isArray(timezones.data)
    ) {
      throw new Error('ERROR: missing timezone data');
    }

    if (timezoneValue.startsWith('(UTC')) {
      // Search for label match
      timezoneObject = timezones.data.find(
        (timezone) => timezone.label === timezoneValue
      );
    } else if (locationRegex.test(timezoneValue)) {
      // Search for Olson match
      timezoneObject = timezones.data.find(
        (timezone) => timezone.location === timezoneValue
      );
    } else {
      // Search for Posix match
      timezoneObject = timezones.data.find(
        (timezone) => timezone.value === timezoneValue
      );
    }

    // If no output format specified, just return the timezone object
    if (typeof format !== 'string') {
      returnValue = timezoneObject;
    } else {
      switch (format.toUpperCase()) {
        case 'POSIX':
          returnValue = timezoneObject?.value;
          break;

        case 'OLSON':
          returnValue = timezoneObject?.location;
          break;

        case 'LABEL':
          returnValue = timezoneObject?.label;
          break;

        default:
          returnValue = timezoneObject;
      }
    }
  } catch (error) {
    Utils.vmsLogger().error(error);
  } finally {
    return returnValue;
  }
};

/**
 * Retrieve area bane from devices array given an area ID
 * @param {*} devices
 * @param {*} areaId
 * @returns
 */
const getAreaName = (devices, areaId) => {
  let area;
  if (!devices || !Array.isArray(devices) || !areaId) {
    return;
  }

  area = devices.find((device) => device.areaId === areaId);
  return area ? area?.areas[0]?.areaName : null;
};

/**
 * Retrieve location name from devices array given a location ID
 * @param {Array} devices
 * @param {String} locationId
 * @returns {String} name of location
 */
const getLocationName = (devices, locationId) => {
  let locationDevice;

  if (!devices || !Array.isArray(devices) || !locationId) {
    return;
  }

  locationDevice = devices.find((device) => device?.locationId === locationId);

  return locationDevice ? locationDevice?.locationName : null;
};

const searchCategoryText = (requestTag, categoryText, type) => {
  let isCatItem = false;
  const tagColor =
    type === 1
      ? requestTag?.tags?.person?.pantColors
      : type === 2
      ? requestTag?.tags?.person?.shirtColors
      : type === 3 && requestTag?.tags?.vehicle?.extColors;
  if (tagColor && tagColor?.length > 0) {
    isCatItem = tagColor.includes(categoryText);
  }
  return isCatItem;
};

const searchCategoryReqBody = (requestTag, categoryText) => {
  let isCatItem = false;
  if (requestTag && requestTag?.length > 0) {
    if (categoryText.indexOf('vehicletype') >= 0) {
      let car = categoryText.replace('vehicletype', getCategoryText().CAR);
      let truck = categoryText.replace('vehicletype', getCategoryText().TRUCK);
      let bicycle = categoryText.replace(
        'vehicletype',
        getCategoryText().BICYCLE
      );
      let motercycle = categoryText.replace(
        'vehicletype',
        getCategoryText().MOTERCYCLE
      );
      let bus = categoryText.replace('vehicletype', getCategoryText().BUS);
      for (let i = 0; i < requestTag.length; i++) {
        if (requestTag[i] === car) {
          isCatItem = true;
          break;
        } else if (requestTag[i] === bicycle) {
          isCatItem = true;
          break;
        } else if (requestTag[i] === truck) {
          isCatItem = true;
          break;
        } else if (requestTag[i] === motercycle) {
          isCatItem = true;
          break;
        } else if (requestTag[i] === bus) {
          isCatItem = true;
          break;
        }
      }
    } else {
      isCatItem = requestTag.includes(categoryText);
    }
  }
  return isCatItem;
};

const getCommonColor = (requestTag, category, type) => {
  const colors = [
    {
      name: 'green',
      id: 0,
      value: '#4EA65A',
      isSelected: searchCategoryText(requestTag, `green`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `green ${category}`),
    },
    {
      name: 'yellow',
      id: 1,
      value: '#E5C261',
      isSelected: searchCategoryText(requestTag, `yellow`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `yellow ${category}`),
    },
    {
      name: 'orange',
      id: 2,
      value: '#E58161',
      isSelected: searchCategoryText(requestTag, `orange`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `orange ${category}`),
    },
    {
      name: 'red',
      id: 3,
      value: '#E56161',
      isSelected: searchCategoryText(requestTag, `red`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `red ${category}`),
    },
    {
      name: 'blue',
      id: 4,
      value: '#61A6E5',
      isSelected: searchCategoryText(requestTag, `blue`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `blue ${category}`),
    },
    {
      name: 'purple',
      id: 5,
      value: '#7461E5',
      isSelected: searchCategoryText(requestTag, `purple`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `purple ${category}`),
    },
    {
      name: 'gray',
      id: 6,
      value: '#7D8BA1',
      isSelected: searchCategoryText(requestTag, `gray`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `gray ${category}`),
    },
    {
      name: 'white',
      id: 7,
      value: '#FFFFFF',
      isSelected: searchCategoryText(requestTag, `white`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `white ${category}`),
    },
    {
      name: 'black',
      id: 8,
      value: '#111111',
      isSelected: searchCategoryText(requestTag, `black`, type),
      isSelectedBody: searchCategoryReqBody(requestTag, `black ${category}`),
    },
  ];
  return colors;
};

const getCategoryName = () => {
  const categoryName = {
    PERSON: 'person',
    FACE: 'face',
    VEHICLE: 'vehicle',
    LICENSEPLATE: 'licensePlate',
    SOUND: 'sound',
  };
  return categoryName;
};

const getCategoryText = () => {
  const categoryName = {
    ADUlT: 'adult age',
    MIDDLE: 'middle age',
    SENIOR: 'senior age',
    YOUNG: 'young age',
    MALE: 'male gender',
    FEMALE: 'female gender',
    OPTICAL: 'opticals',
    HAT: 'hat',
    MASK: 'mask',
    BAG: 'bag',
    TRUCK: 'truck',
    SUV: 'suv',
    CAR: 'car',
    BUS: 'bus',
    BICYCLE: 'bicycle',
    MOTERCYCLE: 'motorcycle',
    BIKE: 'bike',
    GLASSES: 'glasses',
    GUNSHOT: 'gun-shot',
    EXPLOSION: 'explosion',
    GLASSBREAK: 'glass-breaking',
    SCREAM: 'scream',
    AUDIO_START: 'audio-start',
  };
  return categoryName;
};

const generateUUID = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

const getNotificationResources = () => {
  const resource = {
    person: 'camera/event/object-detection',
    vehicle: 'camera/event/object-detection',
    camera: 'camera/event/defocus-detection',
    loitering: 'camera/event/virtual-area',
    tempering: 'camera/event/tamper-detection',
    sound: 'camera/event/sound-classification',
    motion: 'camera/event/motion-detection',
    fog: 'camera/event/fog-detection',
    virtualLine: 'camera/event/virtual-line',
    defocus: 'camera/event/defocus-detection',
    shock: 'camera/event/shock-detection',
    audio: 'camera/event/audio-detectione',
  };
  return resource;
};

const getNotificationEventName = () => {
  const events = {
    person: 'person',
    vehicle: 'vehicle',
    motionStart: 'motion-start',
    motionEnd: 'motion-end',
    fogStart: 'fog-start',
    fogEnd: 'fog-end',
    shockStart: 'shock-start',
    shockEnd: 'shock-end',
    defocusStart: 'defocus-start',
    tamperStart: 'tamper-start',
    tamperEnd: 'tamper-end',
    defocusEnd: 'defocus-end',
    cameraAdd: 'camera-add',
    loitering: 'loitering',
    gunShot: 'gun-shot',
    scream: 'scream',
    explosion: 'explosion',
    glassBreaking: 'glass-breaking',
    cameraOnline: 'camera-online',
    cameraOffline: 'camera-offline',
    virtualLineLeft: 'vl-left',
    virtualLineRight: 'vl-right',
    virtualLineBoth: 'vl-both',
  };
  return events;
};

const getModuleStatus = () => {
  const config =
    process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
      ? megaTron
      : ducloConfig;
  return config;
};

const moveArrayPosition = (arr, old_index, new_index) => {
  try {
    if (arr.length > 0) {
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    }
  } catch (err) {}
};

const detectBrowser = () => {
  let isMobile = false;
  const ismobile =
    /iphone|ipod|android|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(
      navigator.userAgent.toLowerCase()
    );
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      navigator.userAgent.toLowerCase()
    );
  if (isTablet) {
    isMobile = false;
  } else if (ismobile) {
    isMobile = true;
  } else {
    isMobile = false;
  }
  return isMobile;
};

const requestHeader = (corId, optionalCustomHeaders) => {
  return {
    headers: {
      'X-Correlation-ID': corId || generateUUID(),
      ...optionalCustomHeaders,
    },
  };
};

const generatePlaybackPayload = (data) => {
  const appID =
    !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
      ? 'vms'
      : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
      ? 'oncloud'
      : 'vms';
  const time = Math.floor(new Date().getTime() / 1000.0);
  let payload = {
    appid: appID,
    token: data.jwt_token,
    cid: data.correlation_id,
    tid: `${time}`,
    to: data.device_id,
    from: data.accountId,
    msg: {
      action: 'set',
      resource: 'media/handshake',
      properties: {
        uuid: data.playback_uuid,
        type: data.type,
      },
    },
  };

  if (data.type === 'PLAY') {
    payload.msg.properties.start_time = `${data.actualTime}`;
    payload.msg.properties.org_id = data.orgId;
    payload.msg.properties.dev_id = data.device_id;
    payload.msg.properties.direction = data.direction;
    payload.msg.properties.speed = data.speed;
    delete payload['token'];
    delete payload['appid'];
  }

  if (data.type === constants.WEBSOCKET_PLAYBACK_EVENT_SYNC) {
    payload.msg.properties.sync_time = `${data.actualTime}`;
    payload.msg.properties.org_id = data.orgId;
    payload.msg.properties.dev_id = data.device_id;
    delete payload['token'];
    delete payload['appid'];
  }

  if (data.type === 'STOP') {
    payload.msg.properties.org_id = data.orgId;
    payload.msg.properties.dev_id = data.device_id;
    delete payload.msg['action'];
    delete payload['token'];
    delete payload['appid'];
  }

  if (data.type === 'offer') {
    payload.msg.properties.org_id = data.orgId;
    payload.msg.properties.dev_id = data.device_id;
    payload.msg.properties.sdp = data.sdp;
    payload.msg.resource = 'media/streaming';
    delete payload.msg['action'];
    delete payload['token'];
    delete payload['appid'];
  }

  if (data.type === 'ice') {
    payload.msg.properties.org_id = data.orgId;
    payload.msg.properties.dev_id = data.device_id;
    payload.msg.properties.candidate = data.candidate;
    payload.msg.resource = 'media/streaming';
    delete payload.msg['action'];
    delete payload['token'];
    delete payload['appid'];
  }

  if (data.type === 'TRICK_PLAY' && data.speed) {
    payload.msg.resource = 'media/streaming';
    payload.msg.properties.org_id = data.orgId;
    payload.msg.properties.dev_id = data.device_id;
    payload.msg.properties.playback_direction = data.direction;
    payload.msg.properties.direction = data.direction;
    payload.msg.properties.speed = data.speed;
    delete payload['token'];
    delete payload['appid'];
  }

  // if(data.type === "TRICK_PLAY" && data.mode === "PAUSE") {
  //   payload.msg.resource= "media/streaming";
  //   payload.msg.properties.org_id = data.orgId;
  //   payload.msg.properties.dev_id = data.device_id;
  //   payload.msg.properties.mode = data.mode;
  //   delete payload.msg.properties['token'];
  // }

  // if(data.type === "TRICK_PLAY" && data.mode === "RESUME") {
  //   payload.msg.resource= "media/streaming";
  //   payload.msg.properties.org_id = data.orgId;
  //   payload.msg.properties.dev_id = data.device_id;
  //   payload.msg.properties.mode = data.mode;
  // }

  // TODO: THIS CODE WILL BE UPDATED
  // if(data.type === "TRICK_PLAY" && data.skip) {
  //   payload.msg.properties.org_id = data.orgId;
  //   payload.msg.properties.dev_id = data.device_id;
  //   payload.msg.properties.direction = "FORWARD";
  //   payload.msg.properties.skip = data.skip;
  //   payload.msg.resource = 'media/streaming';
  //   delete payload.msg.properties['token'];
  // }

  return payload;
};
const getManufacturer = (name) => {
  if (name === constants.ADD_DEVICE_MANUFRACTURE_NAME_TECHWIN) {
    return constants.ADD_DEVICE_MANUFRACTURE_NAME;
  }
  return constants.ADD_DEVICE_MANUFRACTURE_NAME;
};

/**
 * Checks if an email address string is a valid email address
 * @param {String} emailString - email address
 * @returns {Boolean}
 */
const isEmailValid = (emailString) => {
  const emailRegex =
    /^[_a-z0-9-]+(.[_a-z0-9-\+]+)@[a-z0-9-]+(.[a-z0-9-]+)(.[a-z]{2,3})$/i;

  if (!emailString || typeof emailString !== 'string') return false;

  return emailString.match(emailRegex);
};

const isMatchingDeviceType = (parent, child) => {
  if (parent?.deviceType?.toUpperCase() === DeviceTypeEnum?.NVR) {
    return (
      child.gatewayId === parent.deviceId &&
      child.gatewayId !== child.deviceId &&
      child.gatewayId !== child.parentId
    );
  } else {
    return (
      child.parentId === parent.deviceId && child.parentId !== child.deviceId
    );
  }
};

// Get device list with it's child Devices
const getGroupedDevices = (devices) => {
  if (!devices) {
    return [];
  }
  const groupedDevices = devices.reduce((result, item) => {
    if (item.parentId === item.deviceId && item.deviceId === item.gatewayId) {
      const childItems = devices.filter((a) => isMatchingDeviceType(item, a));
      result.push({ ...item, childDevices: childItems });
    }
    return result;
  }, []);
  return groupedDevices;
};

// All parent devices
const getTotalParentDevices = (devices) => {
  if (!devices) {
    return [];
  }
  const totalDevice = devices.reduce((result, item) => {
    if (item.parentId === item.deviceId && item.deviceId === item.gatewayId) {
      result.push(item);
    }
    return result;
  }, []);
  return totalDevice;
};

// All child devices
const getTotalChildDevices = (devices) => {
  if (!devices) {
    return [];
  }
  const totalDevice = devices.reduce((result, item) => {
    if (
      item?.parentId === item?.deviceId &&
      item?.deviceId === item?.gatewayId
    ) {
      const childItems = devices?.filter((a) => isMatchingDeviceType(item, a));
      if (childItems?.length > 0) {
        result.push(...childItems);
      }
    }
    return result;
  }, []);
  return totalDevice;
};

const getObjectClassEvents = (classEvent) => {
  if (classEvent?.includes('person')) {
    return 'person';
  } else if (classEvent?.includes('vehicle')) {
    return 'vehicle';
  } else if (classEvent?.includes('glasses')) {
    return 'glasses';
  } else if (classEvent?.includes('train')) {
    return 'train';
  } else if (classEvent?.includes('face')) {
    return 'face';
  } else {
    return classEvent;
  }
};

const getObjectClassEventsValue = (classEvent) => {
  if (classEvent?.includes('person')) {
    return 'person';
  } else if (classEvent?.includes('vehicle')) {
    return 'vehicle';
  } else if (classEvent?.includes('glasses')) {
    return 'glasses';
  } else if (classEvent?.includes('train')) {
    return 'train';
  } else if (classEvent?.includes('face')) {
    return 'face';
  } else if (classEvent?.includes('licenseplate')) {
    return 'licenseplate';
  } else if (classEvent?.includes('sound')) {
    return 'sound';
  } else if (classEvent?.includes('motion')) {
    return 'motion';
  } else {
    return classEvent;
  }
};

const isNullString = (stringValue) => {
  if (stringValue !== '' && stringValue !== null && stringValue !== undefined) {
    return false;
  } else {
    return true;
  }
};

const calculateGridStyle = (itemCount) => {
  let gridStyle = {};

  if (itemCount === 1) {
    gridStyle = { gridTemplateColumns: '1fr', gridTemplateRows: '1fr' };
  } else if (itemCount < 3) {
    gridStyle = { gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr' };
  } else if (itemCount < 5) {
    gridStyle = {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
    };
  } else if (itemCount < 7) {
    gridStyle = {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr',
    };
  } else if (itemCount < 9) {
    gridStyle = {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr',
    };
  } else {
    gridStyle = {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr',
    };
  }

  return gridStyle;
};

const getColumnsByCount = (itemCount) => {
  if (itemCount === 1) {
    return 1;
  } else if (itemCount < 3) {
    return 1 / 2;
  } else if (itemCount < 5) {
    return 2 / 4;
  } else if (itemCount < 7) {
    return 2 / 6;
  } else if (itemCount < 9) {
    return 2 / 8;
  } else {
    return 3 / 12;
  }
};

const getResponsiveStyleProps = (itemCount) => {
  return getResponsiveStyle({
    // The Muuri component is virtually divided into 8 columns,
    // the width of the item will be 3 columns (minus the margin).
    columns: getColumnsByCount(itemCount),
    // The margin of the item, it can be any CSS values
    // valid for the margin expressed in "px" or "%".
    margin: '10px',
    // The width/height ratio. If you want to set a static
    // height just set the "height" option in px and remove the "ratio".
    ratio: 16 / 9,
  });
};

const getGridItemRowColumn = (itemCount) => {
  if (itemCount === 1) {
    return [1, 1];
  } else if (itemCount < 3) {
    return [1, 2];
  } else if (itemCount < 5) {
    return [2, 2];
  } else if (itemCount < 7) {
    return [2, 3];
  } else if (itemCount < 9) {
    return [2, 4];
  } else {
    return [3, 4];
  }
};

// Return array of null values
// Which are remained to complete the grid 2X2, 2X3
const getRemainingItemsCount = (itemCount) => {
  let remainingItemCount = 0;
  if (itemCount < 3) {
    remainingItemCount = 0;
  } else if (itemCount < 5) {
    remainingItemCount = 4 - itemCount;
  } else if (itemCount < 7) {
    remainingItemCount = 6 - itemCount;
  } else if (itemCount < 9) {
    remainingItemCount = 8 - itemCount;
  } else {
    remainingItemCount = 12 - itemCount;
  }
  return Array.from(
    { length: remainingItemCount },
    (_, index) => `editId${index}`
  );
};
const amplitudeInit = (accountId) => {
  const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
  amplitude.setSessionId(Date.now());
  return amplitude.init(apiKey, accountId, {
    defaultTracking: true,
    pageViews: true,
    sessions: true,
    formInteractions: true,
    fileDownloads: true,
  });
};
const amplitudeTrack = (AmplitudeEvent, eventProperties = '') => {
  if (eventProperties !== '') {
    return amplitude.track(AmplitudeEvent, eventProperties);
  } else {
    return amplitude.track(AmplitudeEvent);
  }
};

const getUpdatedViewPayload = (view, deviceIdList) => {
  return {
    viewId: view.viewId,
    file: btoa(JSON.stringify(deviceIdList)),
    devices: deviceIdList,
    clientPlatform: view.clientPlatform,
    viewType: view.viewType,
    name: view.name,
    locationId: view.locationId,
  };
};

const filterLicenseData = (list) => {
  const filteredData = list.filter(
    (item) =>
      item.serviceId === 'cloud-storage' &&
      (item.licenseStatus === 'UNASSIGNED' ||
        item.licenseStatus === 'ASSIGNED_ACTIVATION_PENDING')
  );
  return filteredData;
};

const checkStorageExceed = (usedSpace, allocatedSpace) => {
  Utils.vmsLogger().log('usedSpace===', usedSpace);

  const usedPercentage = (usedSpace / allocatedSpace) * 100;
  return usedPercentage >= 90;
};

const getAllColors = () => {
  return [
    getComputedStyle(document.documentElement).getPropertyValue(
      '--custom_color_18'
    ),
    getComputedStyle(document.documentElement).getPropertyValue('--warning_64'),
    getComputedStyle(document.documentElement).getPropertyValue('--success_48'),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--custom_color_11'
    ),
    getComputedStyle(document.documentElement).getPropertyValue('--error_48'),
    getComputedStyle(document.documentElement).getPropertyValue('--error_80'),
    getComputedStyle(document.documentElement).getPropertyValue('--primary_03'),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--avatar_dark_lime'
    ),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--custom_color_1'
    ),
    getComputedStyle(document.documentElement).getPropertyValue('--error_24'),
  ];
};

const getAllVirtualLineColors = () => {
  return [
    getComputedStyle(document.documentElement).getPropertyValue(
      '--avatar_dark_blue'
    ),
    getComputedStyle(document.documentElement).getPropertyValue('--avatar_dark_teal'),
    getComputedStyle(document.documentElement).getPropertyValue('--avatar_dark_yellow'),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--avatar_dark_purple'
    ),
    getComputedStyle(document.documentElement).getPropertyValue('--avatar_dark_pink'),
    getComputedStyle(document.documentElement).getPropertyValue('--avatar_dark_orange'),
    getComputedStyle(document.documentElement).getPropertyValue('--avatar_dark_ultramarine'),
    getComputedStyle(document.documentElement).getPropertyValue(
      '--avatar_dark_red'
    ),
  ];
};

const getMinAvailableIndex = (occupiedIndices) => {
  let minIndex = 0;
  const maxIndex = 8;
  while (minIndex <= maxIndex) {
    if (!occupiedIndices.includes(minIndex)) {
      return minIndex;
    }
    minIndex++;
  }
  return -1;
};

const findTimeZoneFromSelectedValue = (selectedValue) => {
  try {
    const timeZoneList = timezones?.data;
    const selectedTimeZone = timeZoneList?.find(
      (zone) => zone.location === selectedValue || zone.value === selectedValue
    );
    return selectedTimeZone;
  } catch (err) {
    Utils.vmsLogger().log('Error while finding timezone', err);
  }
};

/**
 * Custom console logger which inherits from window.console
 *
 * @returns Object
 */
const vmsLogger = () => {
  const defaultLogger = {
    log: function log() {
      if (
        arguments &&
        typeof arguments?.[0] === 'string' &&
        (arguments?.[0]?.toLowerCase()?.includes('livestream') ||
          arguments?.[0]?.toLowerCase()?.includes('playback'))
      ) {
        window.vmsConsole?.log.apply(window.vmsConsole, arguments);
        return true;
      }
    },
    warn: function warn() {},
    error: function error() {},
    info: function info() {},
    time: function time() {},
    timeEnd: function timeEnd() {},
    trace: function trace() {},
    table: function table() {},
  };

  // Doing this here allows us to enable/disable the
  // logging at any time.
  const systemConfig = localStorage?.getItem('net.duclo.vms.system');

  if (!systemConfig) {
    return defaultLogger;
  }

  const { init, broadcast, register } = JSON.parse(
    localStorage?.getItem('net.duclo.vms.system')
  );

  if (
    init === false &&
    broadcast === true &&
    typeof register === 'string' &&
    parseInt(register.split('.')[2]) === new Date().getDate()
  ) {
    return {
      log: function log() {
        window.vmsConsole?.log.apply(window.vmsConsole, arguments);
        return true;
      },
      error: function error() {
        window.vmsConsole?.error.apply(window.vmsConsole, arguments);
        return true;
      },
      warn: function warn() {
        window.vmsConsole?.warn.apply(window.vmsConsole, arguments);
        return true;
      },
      info: function info() {
        window.vmsConsole?.info.apply(window.vmsConsole, arguments);
        return true;
      },
      time: function time() {
        window.vmsConsole?.time.apply(window.vmsConsole, arguments);
      },
      timeEnd: function timeEnd() {
        window.vmsConsole?.timeEnd.apply(window.vmsConsole, arguments);
        return true;
      },
      trace: function trace() {
        window.vmsConsole?.trace.apply(window.vmsConsole, arguments);
        return true;
      },
      table: function table() {
        window.vmsConsole?.table.apply(window.vmsConsole, arguments);
        return true;
      },
    };
  } else {
    return defaultLogger;
  }
};

const isPageReloaded = () => {
  // Check if the page is loaded from the browser cache
  if (
    performance?.navigation &&
    performance?.navigation?.type === performance?.navigation?.TYPE_RELOAD
  ) {
    return true;
  }

  // Check if the page is reloaded using the newer Navigation API
  if (window?.performance && window?.performance.getEntriesByType) {
    const navigationEntries =
      window?.performance?.getEntriesByType('navigation');
    if (
      navigationEntries?.length > 0 &&
      navigationEntries[0]?.type === 'reload'
    ) {
      return true;
    }
  }

  // If none of the above methods work, assume it's not a reload
  return false;
};

const getTimesinmili = () => {
  const time =
    new Date().getHours() +
    ':' +
    new Date().getMinutes() +
    ':' +
    new Date().getSeconds() +
    ':' +
    new Date().getMilliseconds();
  return time;
};
const nameInitials = (name) => {
  let letter = name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();
  return letter;
};

const getObjectTypes = (properties) => {
  const objType = [
    { typeName: 'Person', isSlected: '0', keyName: 'person-detection',icon:<MdPeopleOutline size={20} />},
    { typeName: 'Face', isSlected: '0', keyName: 'face-detection',icon:<FaRegFaceGrin size={20}  /> },
    {
      typeName: 'Vehicle',
      isSlected: '0',
      keyName: 'vehicle-detection',
      icon:<FaCarSide size={20}  />,
      vehicleTypes: [
        { typeName: 'Bicycle', isSlected: '0', keyName: 'vehicle-bicycle-detection',icon:<IoBicycle size={20}  />},
        { typeName: 'Bus', isSlected: '0', keyName: 'vehicle-bus-detection',icon:<TbBus size={20}  /> },
        { typeName: 'Car', isSlected: '0', keyName: 'vehicle-car-detection',icon:<FaCarSide size={20}  /> },
        { typeName: 'Motorcycle', isSlected: '0', keyName: 'vehicle-motorcycle-detection',icon:<TbMotorbike size={20}  /> },
        { typeName: 'Truck', isSlected: '0', keyName: 'vehicle-truck-detection',icon:<HiOutlineTruck size={20}  /> },
      ],
    },
    { typeName: 'License Plate', isSlected: '0', keyName: 'licenseplate-detection',icon:<FaRegRectangleList size={20}/> },
  ];

  const result = [];
  let vehicleAdded = false; // Track if vehicle type is added

  objType?.forEach(item => {
    // Check if the main item's keyName exists in properties
    if (properties?.[item?.keyName]) {
      if (item?.typeName === 'Vehicle' && !vehicleAdded) {
        result.push(item); // Add Vehicle only once
        vehicleAdded = true; // Mark vehicle as added
      } else if (item?.typeName !== 'Vehicle') {
        result.push(item); // Add other types
      }
    }

    // Check for vehicleTypes if they exist
    if (item?.vehicleTypes) {
      const filteredVehicleTypes = item?.vehicleTypes.filter(vehicle => properties?.[vehicle.keyName]);
      if (filteredVehicleTypes.length > 0) {
        // Only add vehicle types if the main vehicle item is not already added
        if (!vehicleAdded) {
          result.push({ ...item, vehicleTypes: filteredVehicleTypes });
        } else {
          // Add vehicle types separately if the vehicle object is already included
          result[result.length - 1].vehicleTypes = filteredVehicleTypes;
        }
      }
    }
  });

  return result;
};

const getObjectTypesVirtualAreaIVA = (properties) => {
  const objType = [
    { typeName: 'Person', isSlected: '0', keyName: 'virtual-area-person',icon:<MdPeopleOutline size={20} />},
    {
      typeName: 'Vehicle',
      isSlected: '0',
      keyName: 'virtual-area-vehicle',
      icon:<FaCarSide size={20}  />,
      vehicleTypes: [
        { typeName: 'Bicycle', isSlected: '0', keyName: 'virtual-area-vehicle-bicycle-detection',icon:<IoBicycle size={20}  />},
        { typeName: 'Bus', isSlected: '0', keyName: 'virtual-area-vehicle-bus-detection',icon:<TbBus size={20}  /> },
        { typeName: 'Car', isSlected: '0', keyName: 'virtual-area-vehicle-car-detection',icon:<FaCarSide size={20}  /> },
        { typeName: 'Motorcycle', isSlected: '0', keyName: 'virtual-area-vehicle-motorcycle-detection',icon:<TbMotorbike size={20}  /> },
        { typeName: 'Truck', isSlected: '0', keyName: 'virtual-area-vehicle-truck-detection',icon:<HiOutlineTruck size={20}  /> },
      ],
    }
  ];

  const result = [];
  let vehicleAdded = false; // Track if vehicle type is added

  objType?.forEach(item => {
    // Check if the main item's keyName exists in properties
    if (properties?.[item?.keyName]) {
      if (item?.typeName === 'Vehicle' && !vehicleAdded) {
        result.push(item); // Add Vehicle only once
        vehicleAdded = true; // Mark vehicle as added
      } else if (item?.typeName !== 'Vehicle') {
        result.push(item); // Add other types
      }
    }

    // Check for vehicleTypes if they exist
    if (item?.vehicleTypes) {
      const filteredVehicleTypes = item?.vehicleTypes.filter(vehicle => properties?.[vehicle.keyName]);
      if (filteredVehicleTypes.length > 0) {
        // Only add vehicle types if the main vehicle item is not already added
        if (!vehicleAdded) {
          result.push({ ...item, vehicleTypes: filteredVehicleTypes });
        } else {
          // Add vehicle types separately if the vehicle object is already included
          result[result.length - 1].vehicleTypes = filteredVehicleTypes;
        }
      }
    }
  });

  return result;
};

const getObjectTypesVirtualLine = (properties) => {
  const objType = [
    { typeName: 'Person', isSlected: '0', keyName: 'virtual-line-obj-person',icon:<MdPeopleOutline size={20} />},
    {
      typeName: 'Vehicle',
      isSlected: '0',
      keyName: 'virtual-line-obj-vehicle',
      icon:<FaCarSide size={20}  />,
      vehicleTypes: [
        { typeName: 'Bicycle', isSlected: '0', keyName: 'virtual-line-obj-vehicle_bicycle',icon:<IoBicycle size={20}  />},
        { typeName: 'Bus', isSlected: '0', keyName: 'virtual-line-obj-vehicle_bus',icon:<TbBus size={20}  /> },
        { typeName: 'Car', isSlected: '0', keyName: 'virtual-line-obj-vehicle_car',icon:<FaCarSide size={20}  /> },
        { typeName: 'Motorcycle', isSlected: '0', keyName: 'virtual-line-obj-vehicle_motorcycle',icon:<TbMotorbike size={20}  /> },
        { typeName: 'Truck', isSlected: '0', keyName: 'virtual-line-obj-vehicle_truck',icon:<HiOutlineTruck size={20}  /> },
      ],
    }
  ];

  const result = [];
  let vehicleAdded = false; // Track if vehicle type is added

  objType?.forEach(item => {
    // Check if the main item's keyName exists in properties
    if (properties?.[item?.keyName]) {
      if (item?.typeName === 'Vehicle' && !vehicleAdded) {
        result.push(item); // Add Vehicle only once
        vehicleAdded = true; // Mark vehicle as added
      } else if (item?.typeName !== 'Vehicle') {
        result.push(item); // Add other types
      }
    }

    // Check for vehicleTypes if they exist
    if (item?.vehicleTypes) {
      const filteredVehicleTypes = item?.vehicleTypes.filter(vehicle => properties?.[vehicle.keyName]);
      if (filteredVehicleTypes.length > 0) {
        // Only add vehicle types if the main vehicle item is not already added
        if (!vehicleAdded) {
          result.push({ ...item, vehicleTypes: filteredVehicleTypes });
        } else {
          // Add vehicle types separately if the vehicle object is already included
          result[result.length - 1].vehicleTypes = filteredVehicleTypes;
        }
      }
    }
  });

  return result;
};

const app_version = 'WebVMS-1.29.1';
const app_version_date = 'Nov 14, 2024';

export {
  amplitudeInit,
  amplitudeTrack,
  app_version,
  app_version_date,
  nameInitials,
  areArraysOfObjectsEqual,
  calculateBeforeAndAfterOffsets,
  calculateGridStyle,
  checkIfMobileView,
  checkObjectEmpty,
  checkStorageExceed,
  checkURLValidity,
  convertTimezoneDateToLocalDate,
  daysToMilliseconds,
  decodeBase64StringToImageFile,
  detectBrowser,
  encodeImageFileToBase64String,
  fetchDateInUnix,
  fetchJpegResponses,
  filterLicenseData,
  findTimeZoneFromSelectedValue,
  formatCurrencyWithSmallDecimals,
  formatMACAddress,
  generateCacheKey,
  generatePlaybackPayload,
  generateUUID,
  geAccountDetailsByAccountId,
  getAccountAvatarURL,
  getAllColors,
  getAllVirtualLineColors,
  getAreaName,
  getBeforeAndAfterUnixTimes,
  getCategoryName,
  getCategoryText,
  getCodecAvailability,
  getColumnsByCount,
  getCommonColor,
  getCurrentTabProperty,
  getDate,
  getDateDifferenceInDays,
  getDateFormatStringByLocale,
  getDateFromEpoch,
  getDetectionValue,
  getDeviceSettingResource,
  getDeviceStatus,
  getDropDownPair,
  getDropDownPairRes,
  getElapsedTime,
  getFeatureValue,
  getGreatestCommonDivisor,
  getGridItemRowColumn,
  getGroupedDevices,
  getHourFromEpoch,
  getHoursFromValue,
  getHourPast,
  getHourPastByValue,
  getInitialsFromFullName,
  getJpegFromCache,
  getLicensesExpiring,
  getLocationName,
  getLoggedInUserRole,
  getManufacturer,
  getMinAvailableIndex,
  getModalData,
  getModuleStatus,
  getMonthFromEpoch,
  getNotificationEventName,
  getNotificationResources,
  getOfflineCameras,
  getPastDaysTime,
  getPastDaysInMilliseconds,
  getPastWeekDayName,
  getPropertyShowStatus,
  getPropertyValueIfExist,
  getPTZShowStatus,
  getRemainingItemsCount,
  getResponsiveStyleProps,
  getStartEndMin,
  getTimeFilters,
  getTimeStampFromDate,
  getTimezone,
  getTotalChildDevices,
  getTotalParentDevices,
  getUnclaimedDevices,
  getUnixDate,
  getUpdatedViewPayload,
  getWeekDays,
  getWeekFromEpoch,
  getYearFromEpoch,
  getObjectClassEvents,
  getObjectTypes,
  getObjectTypesVirtualAreaIVA,
  getObjectTypesVirtualLine,
  getObjectClassEventsValue,
  initCache,
  isEmailValid,
  isLicenseExpired,
  isMatchingDeviceType,
  isNullString,
  isPageReloaded,
  mapUserPolicies,
  moveArrayPosition,
  removeArrayElement,
  replaceStringValues,
  replaceStringWithKeyValueArray,
  requestHeader,
  revokeAllObjectURLs,
  storeJpegsInCache,
  vmsLogger,
  getTimesinmili,
};
