import { constants } from '../../../helpers';
import { FaCircle } from 'react-icons/fa';
import './IncidentStatusMap.scss';

const incidentStatusMap = {
  OPEN: (
    <div className="status-field">
      <FaCircle className="status-indicator status-open" />
      {constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_OPEN}
    </div>
  ),
  REOPENED: (
    <div className="status-field">
      <FaCircle className="status-indicator status-reopened" />
      {constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_REOPENED}
    </div>
  ),
  INCOMPLETE_DETAILS: (
    <div className="status-field">
      <FaCircle className="status-indicator status-incomplete" />
      {constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_INCOMPLETE}
    </div>
  ),
  CLOSED: (
    <div className="status-field">
      <FaCircle className="status-indicator status-closed" />
      {constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED}
    </div>
  ),
  CLOSED_RESOLVED: (
    <div className="status-field">
      <FaCircle className="status-indicator status-closed-resolved" />
      {constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED_RESOLVED}
    </div>
  ),
  CLOSED_UNRESOLVED: (
    <div className="status-field">
      <FaCircle className="status-indicator status-closed-unresolved" />
      {constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED_UNRESOLVED}
    </div>
  ),
  REOPENED_RESOLVED: (
    <div className="status-field">
      <FaCircle className="status-indicator status-closed-unresolved" />
      {constants.INCIDENTS_PAGE_GRID_INCIDENT_STATUS_REOPENED_RESLOVED}
    </div>
  ),
};

export default incidentStatusMap;
